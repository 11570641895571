import { SelectedVariants } from '@wix/bookings-uou-types';
import { PriceOptionNumberOfParticipants } from '../../Widget/PriceOptionDropdown/PriceOptionDropdown';
import { CreateActionParams } from '../actions';

export type OnSelectedVariants = (
  priceOptionData: PriceOptionNumberOfParticipants[],
  optionId: string,
) => void;

export function createOnSelectedVariantsAction({
  getControllerState,
  internalActions: { calculatePaymentDetails },
}: CreateActionParams): OnSelectedVariants {
  return async (
    priceOptionData: PriceOptionNumberOfParticipants[],
    optionId: string,
  ) => {
    const [state, setState] = getControllerState();
    const { dynamicPriceInfo } = state;
    const selectedVariants: SelectedVariants[] = priceOptionData.reduce(
      (
        variants: SelectedVariants[],
        option: PriceOptionNumberOfParticipants,
      ) => {
        return option?.numberOfParticipants
          ? [
              ...variants,
              {
                numberOfParticipants: option?.numberOfParticipants,
                choices: [{ optionId, custom: option.title }],
              },
            ]
          : variants;
      },
      [] as SelectedVariants[],
    );

    setState({
      dynamicPriceInfo: {
        ...dynamicPriceInfo,
        selectedVariants,
      },
    });
    await calculatePaymentDetails({});
  };
}

import { CreateActionParams } from '../actions';
import { getFirstAvailablePaymentOptionId } from '../../../../utils/payment/payment';
import {
  BookErrorType,
  CreateBookingErrorType,
} from '../../../../types/errors';

export type SetNumberOfParticipants = (numberOfParticipants: number) => void;

export function createSetNumberOfParticipantsAction({
  getControllerState,
  context,
  internalActions,
}: CreateActionParams): SetNumberOfParticipants {
  return async (numberOfParticipants: number) => {
    const [state, setState] = getControllerState();
    const {
      calculatePaymentDetails,
      setPaymentOption,
      errorHandlers,
    } = internalActions;
    const {
      isPricingPlanInstalled,
      selectedPaymentOptionId,
      service,
      pricingPlanDetails,
      memberships,
      businessInfo,
      formInputs,
      couponInfo,
      isBookingsOnEcom,
      dynamicPriceInfo,
    } = state;
    const { t, settings } = context;
    setState({
      formInputs: {
        ...formInputs,
        numberOfParticipants,
      },
    });
    if (isBookingsOnEcom) {
      errorHandlers.removeErrors([
        CreateBookingErrorType.SESSION_CAPACITY_EXCEEDED,
        CreateBookingErrorType.SCHEDULE_CAPACITY_EXCEEDED,
      ]);
    } else {
      errorHandlers.removeErrors([BookErrorType.SESSION_CAPACITY_EXCEEDED]);
    }

    await calculatePaymentDetails({
      couponCode: couponInfo.appliedCoupon?.couponCode,
    });

    const isDynamicPreferenceType = dynamicPriceInfo?.isDynamicPreferenceType!;
    const firstAvailableOptionId = getFirstAvailablePaymentOptionId({
      service,
      pricingPlanDetails,
      memberships,
      isPricingPlanInstalled,
      businessInfo,
      numberOfParticipants,
      selectedPaymentOptionId,
      isDynamicPreferenceType,
      t,
      settings,
    });
    if (firstAvailableOptionId) {
      setPaymentOption(firstAvailableOptionId);
    }
  };
}
